import React from "react";
import {Image} from "@alterpage/gatsby-plugin-image";

import {wrapper, imageBox, imageRatio, nameLine, button} from './service-card.module.scss';

import {IService} from "../../models/service.model";

import Button from "../atoms/button";

interface IServiceProps {
    className?: string,
    service: IService;
    showButton?: boolean;
}

const ServiceCard: React.FC<IServiceProps> = ({className, service, showButton}) => {

    return (
        <div className={`${wrapper} ${className}`}>
            <Image className={imageBox} ratioClass={imageRatio} media={service.media} objectFit={'contain'} />
            <p className={nameLine}>{service.name}</p>
            <Button as={'link'} to={service.pathname} stylePreset={'main'} showPointer={false} className={button}>
                Sprawdź
            </Button>
        </div>
    )
}

export default ServiceCard;